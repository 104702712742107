import { Box, Flex, Spinner, Text } from "@chakra-ui/react";

import InfiniteScroll from "react-infinite-scroll-component";
import { useAppStore } from "../../../store/AppStore";
import NetworkWrapper from "../../../components/wrapper/NetworkWrapper";
import { useInfiniteCase } from "../../../hooks/api/useCaseApi";
import { ICaseResponseObject } from "../../../config/types";
import CaseCard from "@components/atom/CaseCard";
import { extractQueryParams } from "@utils";
import CrmCard from "@components/molecules/CrmCard";
import { useEffect } from "react";
import { pageView } from "@config/event";
import sendTrace from "@hooks/api/useSendTracing";

export default function AllCase() {
  const loginUser = useAppStore(state => state.loginUser);

  const { data, fetchNextPage, hasNextPage, ...networkProp } = useInfiniteCase({
    source: loginUser.dedicatedToClientId,
    ...extractQueryParams(window.location.href),
  });

  const allPages = data?.pages.flatMap((page: any) => page.data) || [];

  useEffect(() => {
    pageView("All Case");
  }, []);
  useEffect(() => {
    const eventTracingData = 
    {
      user: { 'email': loginUser?.email ?? '' },
      data:{
        eventName: "HM_CASE_PAGE_OPENED", 
        trace: { caseID: "-", page: "AllCase" }
      }
    }
    sendTrace(eventTracingData)
  },[])

  return (
    <Flex direction="column">
      <NetworkWrapper status={networkProp}>
        <>
          <InfiniteScroll
            dataLength={allPages.length || 0}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <center style={{ marginTop: 40 }}>
                {" "}
                <Spinner size="md" />
              </center>
            }
            endMessage={
              <Box shadow="2xl" bg="green-100" borderRadius="sm" mt="5" px="5" py="3">
                <Text color="green-800" fontSize="sm" fontWeight="semibold" fontFamily="iphone" transform="initial">
                  🎉 No more case available to show.
                </Text>
              </Box>
            }
          >
            {allPages?.map((props: ICaseResponseObject, index: number) => (
              <CrmCard key={index} {...props} />
            ))}
          </InfiniteScroll>
        </>
      </NetworkWrapper>
    </Flex>
  );
}
